class hostForLocation {
    static getLocationForHost(hostLoction, backend) {
        
        hostLoction = 'client.getshopster.net';
        const analyticsHost = 'client.cloud.getshopster.net';
        const mainHost = 'getshopster.net';
        
        this['host'] = {
            // analytics: `https://analytics-service.v1.${analyticsHost}`,
            analytics: `https://analytics-service.v2.${analyticsHost}`,
            admin: `https://admin.${hostLoction}`,
            maps: `https://maps.${hostLoction}`,
            api: `https://api.getshopster.net`,
            export: `https://export.cloud.${mainHost}`,
            cms: `https://cms.wifizone.me`,
            precampaign: `https://pre-campaign.cloud.${mainHost}`,
            clientGetShopster: `https://${hostLoction}`,
            clientCloudGetshopster: `https://client.cloud.${mainHost}`,
            mainGetShopster: `https://${mainHost}`,
            outdoorMapsGetShopster: `https://outdoor-maps.${mainHost}`,
            pageConstructorGetshopster: `https://pageconstructor.${mainHost}`,
            clientDevGetShopster: `https://client.dev.${mainHost}`,
            ssoWifiZoneMe: `https://sso.wifizone.me`,
            oohMarketerLv: `https://ooh.marketer.lv`


        };
        if (backend !== undefined && backend.checks && Object.keys(backend).length !== 0) {
            // console.log(backend);
            Object.keys(backend.checks).forEach((key) => {
                if (backend.checks && backend.checks[key]) {
                    this['host'][key] = backend[key];
                }
            });

            
        }

    
    }

    static getHostName() {
        
        return this.host;
    }
    // static getHostNameAdmin () {
    //     return this.host.analytics;
    // }
    // static getHostNameMaps () {
    //     return this.host.maps;
    // }
    // static getHostNameApi () {
    //     return this.host.api;
    // }
}

export default hostForLocation;
