import React, { useState } from 'react';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import styled from 'styled-components/macro';

const PdfTopPanel = React.memo(({ sectionsByKeyName, currentSubSection, currentSection, ...props }) => {
    const [theme] = useState(getTheme());

    let obj = {};

    if (sectionsByKeyName && sectionsByKeyName[currentSection]) {
        const subsection = sectionsByKeyName[currentSection].subsections.filter((item) => item.key_name === currentSubSection)[0];
        obj['name_en'] = subsection.translations['en'].name;
        obj['name_ru'] = subsection.translations['ru'].name;
    }

    return (
        <Container backgroundColor={theme.palette.themePrimary}>
            <SectionName>
                {props.lang === 'ru'
                    ? sectionsByKeyName[currentSection].translations['ru'].name + ': ' + obj.name_ru
                    : sectionsByKeyName[currentSection].translations['en'].name + ': ' + obj.name_en}
            </SectionName>
            <DivWrapperLogoSC>
                <Logo logo={props.logo}></Logo>
            </DivWrapperLogoSC>
            
        </Container>
    );
});

export default PdfTopPanel;

const Container = styled.div`
    background-color: ${(p) => p.backgroundColor};
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 60px;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const SectionName = styled.div`
    text-transform: capitalize;
    font-size: 20px;
    color: white;
    font-weight: 500;
`;

const Logo = styled.div`
    margin-left: auto;
    height: 80%;
    width: 100%;
    background: ${(p) => `url(${p.logo}) center right no-repeat`};
    background-size: contain;
`;

const DivWrapperLogoSC = styled.div`
    display: grid;
    height: 100%;
    width: 300px;
    justify-items: center;
    align-items: center;
`
