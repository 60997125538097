const getloginByToken = (action, state) => {
    if (action.json.username !== undefined) {
        
        return Object.assign({}, state, {
            statusSucces: true,
            userName: action.json.username,
            userId: action.json.id,
            products: action.json.products,
            options: action.json.options,
            owner: action.json.owner,
            project: action.json.project,
            statusLoadLogin: false,
            white_label_settings: action.json.white_label_settings ? action.json.white_label_settings : stateInit.white_label_settings,
            errorStatus: ''
        });
    } else {
        return Object.assign({}, state, {
            statusSucces: false,
            userName: null,
            userId: null,
            products: [],
            options: [],
            statusLoadLogin: false,
            errorStatus: 'Error'
        });
    }
};

const getToken = (action, state) => {
    return Object.assign({}, state, {
        token: action.token,
    });
};

const getLoadLogin = (action, state) => {
    return Object.assign({}, state, {
        statusLoadLogin: true,
    });
};

const handleReactGAEvent_Login = (action, state) => {
    const GAEvent = { ...action.payload };
    return { ...state, GAEvent };
};

const fillSharedCloudHash = (action, state) => {
    const { shared_cloud_hash } = action.payload;
    return { ...state, shared_cloud_hash };
};

const toggleOverlaySpinner = (action, state) => {
    const { show } = action.payload;
    return { ...state, showOverlaySpinner: show };
};

let stateInit = {
    showOverlaySpinner: false,
    shared_cloud_hash: null,
    GAEvent: null,
    statusSucces: false,
    userName: null,
    owner: undefined,
    project: undefined,
    userId: null,
    products: [],
    token: '',
    statusLoadLogin: false,
    white_label_settings: null,
    errorStatus: ''
   
};

const LoginApp = (state = stateInit, action) => {
    switch (action.type) {
        case 'getloginByToken':
            return getloginByToken(action, state);
        case 'getToken':
            return getToken(action, state);
        case 'getLoadLogin':
            return getLoadLogin(action, state);
        case 'handleReactGAEvent_Login':
            return handleReactGAEvent_Login(action, state);
        case 'fillSharedCloudHash':
            return fillSharedCloudHash(action, state);
        case 'toggleOverlaySpinner':
            return toggleOverlaySpinner(action, state);
        default:
            return state;
    }
};
export default LoginApp;
