import * as React from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';
// import { Redirect } from 'react-router';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { DirectionalHint } from 'office-ui-fabric-react/lib/ContextualMenu';
import Cookies from 'universal-cookie';
import hostForLocation from '../../../hostnames/hostname'
const cookie = new Cookies();

const Container = styled.div`
    flex-shrink: 0;
    * {
        background-color: transparent !important;
        color: white;
        font-weight: 500;
        :focus {
            outline: none;
        }
        :hover {
            background-color: transparent !important;
            color: white;
            text-decoration: none;
            * {
                color: white !important;
            }
        }
        :active {
            * {
                color: white !important;
            }
        }
    }
`;

// const StContextualMenu = styled(ContextualMenu)`
//     * {
//         min-width: auto !important;
//     }
// `;

// const CustomMenu = props => {
//     return <StContextualMenu {...props} />;
// };

// const CustomMenuItem = props => {
//     const buttonOnMouseClick = () => alert(`${props.item.text} clicked`);
//     return <ContextualMenuItem {...props} onClick={buttonOnMouseClick} />;
// };

const itemStyles = {
    root: [
        {
            selectors: {
                ':focus': {
                    outline: 'none',
                },
            },
        },
    ],
    label: { fontSize: 14 },
};

const menuStyles = {
    root: { minWidth: 'auto', right: '0px' },
    subComponentStyles: { menuItem: itemStyles, callout: {} },
};

const shareIcon = { iconName: 'Share' };
const stackStyles = { root: { height: 44 } };

const TopCommandBar = ({ lang, routing, options, backend, userName, history, ...props }) => {
    const toggleShareLinkModal = () => {
        props.toggleModal({
            show: true,
            modalType: 'cloud_share',
            modalTitle: _t.t('Поделиться ссылкой'),
            modalText: '',
            height: 'auto',
            width: '40%',
            modalArgs: {
                name: '',
                id: '',
            },
        });
    };

    const shareMenuProps = {
        // contextualMenuAs: CustomMenu,
        // contextualMenuItemAs: CustomMenuItem,
        directionalHint: DirectionalHint.bottomRightEdge,
        styles: menuStyles,
        items: [
            // {
            //     key: 'Actions',
            //     itemType: ContextualMenuItemType.Header,
            //     text: 'Actions',
            //     itemProps: {
            //         lang: 'en-us'
            //     }
            // },
            {
                key: 'upload',
                iconProps: {
                    iconName: 'Share',
                    style: {
                        color: 'salmon',
                    },
                },
                text: _t.t('Поделиться ссылкой'),
                title: 'Upload a file',
                onClick: () => toggleShareLinkModal(),
            },
        ],
    };

    const langMenuProps = {
        // contextualMenuAs: CustomMenu,
        // contextualMenuItemAs: CustomMenuItem,
        directionalHint: DirectionalHint.bottomRightEdge,
        styles: menuStyles,
        items: [
            {
                key: 'en',
                text: 'En',
                onClick: () => onChange('en'),
                // iconProps: { iconName: 'Mail' }
            },
            {
                key: 'ru',
                text: 'Ru',
                onClick: () => onChange('ru'),
                // iconProps: { iconName: 'Calendar' }
            },
        ],
    };

    const logoutItems = [
        {
            key: 'logout',
            text: _t.t('Выйти'),
            onClick: () => onLogout(),
            // iconProps: { iconName: 'Mail' }
        },
    ];

    // if (options.includes('role:superuser') || options.includes('role:developer')) {
    //     logoutItems.push({
    //         key: 'backend_cookies',
    //         text: 'backend cookies',
    //         onClick: () => toggleBackEndCookiesModal(),
    //     });
    // }

    const logoutMenuProps = {
        // alignTargetEdge: false,
        // coverTarget: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        styles: menuStyles,
        items: logoutItems,
    };

    // const toggleBackEndCookiesModal = () => {
    //     props.toggleModal({
    //         show: true,
    //         modalType: 'backend_cookies',
    //         modalTitle: _t.t('Редактирование backend cookies'),
    //         modalText: '',
    //         height: 'auto',
    //         width: '60%',
    //         modalArgs: {
    //             name: '',
    //             id: '',
    //             props: { backend },
    //         },
    //     });
    // };

    const onChange = (lng) => {
        // try {
        //     const currentURL = routing.locationBeforeTransitions.pathname;
        //     try {
        //         const newURL = `/${lng}${currentURL.slice(3)}`;
        //         setNewUrl(newURL);
        //         setRedirect(true);
        //     } catch (error) {}
        // } catch (error) {}
        props.onChange(lng);
    };

    
    const onLogout = () => {
        cookie.remove('xtoken', { path: '/' });
        history.push('/');
        window.location.replace(`${hostForLocation.getHostName().ssoWifiZoneMe}/accounts/logout`);
    };

    return (
        <Container>
            {/* {redirect && <Redirect push to={newURL} />} */}
            <Stack horizontal styles={stackStyles}>
                <CommandBarButton iconProps={shareIcon} menuProps={shareMenuProps} />
                <CommandBarButton
                    // iconProps={addIcon}
                    text={lang.charAt(0).toUpperCase() + lang.slice(1)}
                    // Set split=true to render a SplitButton instead of a regular button with a menu
                    // split={true}
                    menuProps={langMenuProps}
                />
                <CommandBarButton
                    // iconProps={mailIcon}
                    text={userName}
                    menuProps={logoutMenuProps}
                />
            </Stack>
        </Container>
    );
};

export default TopCommandBar;
