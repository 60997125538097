// var React = require('react');
import Switch from 'rc-switch';
// var ModalBoxImage = require('./modalImage.jsx');
import ModalImage from './modalImage';
// var lang = require('../../../lang/func.js');

import React, { Component } from 'react';
import AppActions from '../actions/actions';
import AppStore from '../stores/store';
import hostForLocation from '../../../hostnames/hostname'

class Authorization extends Component {
    state = {
        statusSettingsAuth: AppStore.getState().statusSettingsAuth,
        statusSms: AppStore.getState().statusSms,
        statusButton: AppStore.getState().statusButton,
        statusVK: AppStore.getState().statusVK,
        statusFb: AppStore.getState().statusFb,
        statusOk: AppStore.getState().statusOk,
        statusPass: AppStore.getState().statusPass,
        sessionPeriod: AppStore.getState().authParams.sessionPeriod,
        authPeriod: AppStore.getState().authParams.authPeriod,
        nextConnectionPeriod: AppStore.getState().authParams.nextConnectionPeriod,
        traficVolume: AppStore.getState().authParams.traficVolume,
        redirectUrl: AppStore.getState().authParams.redirectUrl,
        inputPass: AppStore.getState().inputPass,
        statusSocial: AppStore.getState().statusSocial,
        VKAutoPost: AppStore.getState().VKAutoPost,
        FBAutoPost: AppStore.getState().FBAutoPost,
        statusShowModalBoxImage: AppStore.getState().statusShowModalBoxImage,
        colorBorderRedirectUrl: { borderColot: null },
        statusCall: AppStore.getState().statusCall,
        inputCall: AppStore.getState().inputCall,
    };
    onClickSettings() {
        // console.log('auth', this.props.location);

        

        window.location.replace(
            `${hostForLocation.getHostName().pageConstructorGetshopster}/` +
                '?token=' +
                this.props.token +
                '&page_id=' +
                this.props.prop.id +
                '&back_url=' +
                this.props.location +
                '&lang=' +
                this.props.lang,
            '_blank'
        );
    }
    internalState = {
        isChekedAutoPostVk: AppStore.getState().VKAutoPost.status,
        isChekedAutoPostFb: AppStore.getState().FBAutoPost.status,
    };
    _onChange() {
        this.setState({
            statusSettingsAuth: AppStore.getState().statusSettingsAuth,
            statusSms: AppStore.getState().statusSms,
            statusButton: AppStore.getState().statusButton,
            statusVK: AppStore.getState().statusVK,
            statusFb: AppStore.getState().statusFb,
            statusOk: AppStore.getState().statusOk,
            statusPass: AppStore.getState().statusPass,
            sessionPeriod: AppStore.getState().authParams.sessionPeriod,
            authPeriod: AppStore.getState().authParams.authPeriod,
            nextConnectionPeriod: AppStore.getState().authParams.nextConnectionPeriod,
            traficVolume: AppStore.getState().authParams.traficVolume,
            redirectUrl: AppStore.getState().authParams.redirectUrl,
            inputPass: AppStore.getState().inputPass,
            statusSocial: AppStore.getState().statusSocial,
            VKAutoPost: AppStore.getState().VKAutoPost,
            FBAutoPost: AppStore.getState().FBAutoPost,
            statusShowModalBoxImage: AppStore.getState().statusShowModalBoxImage,
            statusCall: AppStore.getState().statusCall,
            inputCall: AppStore.getState().inputCall,
        });
        setTimeout(() => {
            this.setState({});
        }, 10);
        this.internalState.isChekedAutoPostVk = AppStore.getState().VKAutoPost.status;
        this.internalState.isChekedAutoPostFb = AppStore.getState().FBAutoPost.status;
    }
    componentDidMount() {
        AppActions.getFiles(this.props.token);
        AppStore.addChangeStoreModuleListener(this._onChange.bind(this));
    }

    componentWillUnmount() {
        AppStore.removeChangeStoreModuleListener(this._onChange.bind(this));
    }

    onChangeSMS(e) {
        // console.log(e);
        AppActions.onChangeSMS(e);
    }
    onChangeButton(e) {
        AppActions.onChangeButton(e);
    }
    onChangeVK(e) {
        AppActions.onChangeVK(e);
    }
    onChangeFb(e) {
        AppActions.onChangeFb(e);
    }
    onChangeOk(e) {
        AppActions.onChangeOk(e);
    }
    onChangePass(e) {
        AppActions.onChangePass(e);
    }
    onChangeInputPass(e) {
        AppActions.onChangeInputPass(e.target.value);
    }
    onChangeSessionPeriod(e) {
        AppActions.onChangeSessionPeriod(e.target.value);
    }
    onChangeAuthPeriod(e) {
        AppActions.onChangeAuthPeriod(e.target.value);
    }
    onChangeTraficVolume(e) {
        if (e.target.value <= 4000) {
            // console.log(e.target.value)
            AppActions.onChangeTraficVolume(e.target.value);
        }
    }
    onChangeRedirectUrl(e) {
        

        AppActions.onChangeRedirectUrl(e.target.value);
        this.setState({
            colorBorderRedirectUrl: { borderColor: null },
        });
    }
    onChangeAutoPostVk(e) {
        // this.internalState.isChekedAutoPostVk
        if (this.internalState.isChekedAutoPostVk === true) {
            this.internalState.isChekedAutoPostVk = false;
        } else if (this.internalState.isChekedAutoPostVk === false) {
            this.internalState.isChekedAutoPostVk = true;
        }
        AppActions.onChangeAutoPostVk(this.internalState.isChekedAutoPostVk);
    }
    onChangeAutoPostFb() {
        if (this.internalState.isChekedAutoPostFb === true) {
            this.internalState.isChekedAutoPostFb = false;
        } else if (this.internalState.isChekedAutoPostFb === false) {
            this.internalState.isChekedAutoPostFb = true;
        }
        AppActions.onChangeAutoPostFb(this.internalState.isChekedAutoPostFb);
    }
    onChangeRadioVKName(e) {
        // console.log('>>>>>', e.target);
        AppActions.onChangeRadioVKName();
    }
    onChangeRadioVKRepost(e) {
        // console.log('<<<<<<', e.target);
        AppActions.onChangeRadioVKRepost();
    }
    onReset() {
        var statusDelete = window.confirm('Сбросить настройик');
        // console.log('template.jsx', statusDelete);
        if (statusDelete === true) {
            AppActions.onReset();
        }
    }
    onChangeVkInputReport(e) {
        AppActions.onChangeVkInputReport(e.target.value);
    }
    onChangeVkInputNameApp(e) {
        AppActions.onChangeVkInputNameApp(e.target.value);
    }
    onChangeVkInputNameMessage(e) {
        AppActions.onChangeVkInputNameMessage(e.target.value);
    }
    onChangeFBLInk(e) {
        AppActions.onChangeFBLInk(e.target.value);
    }
    onChangeFBTitle(e) {
        AppActions.onChangeFBTitle(e.target.value);
    }
    onChangeFBDiscrip(e) {
        AppActions.onChangeFBDiscrip(e.target.value);
    }
    openModalImage() {
        AppActions.openModalImage('authFB');
    }
    deleteImage() {
        AppActions.deleteImage();
    }
    onCheckCall(e) {
        // console.log('aut', e);
        AppActions.onCheckCall(e);
    }
    onChangeCall(e) {
        AppActions.onChangeCall(e.target.value * 24 * 60 * 60);
    }
    render() {
        // console.log("store>>>>>>>>>>>>render", this.state)
        if (document.getElementById('tultip1') !== null) {
            // document.getElementById('tultip1').innerHTML = lang.getState().connectionsTultip.settings_panel.id_of_post.body
            // document.getElementById('tultip3').innerHTML = lang.getState().connectionsTultip.connections.auth_schemes.body
        }
        if (document.getElementById('tultip2') !== null) {
            // document.getElementById('tultip2').innerHTML = lang.getState().connectionsTultip.settings_panel.apps.body
        }

        // console.log(this.state.statusPass);
        // console.log('sdsd>>>>>>>', lang.getState().pages.settings.auth.textNotAuth);
        var componentSettingsAuth,
            PassComponent = null,
            styleSectionsHeight = null,
            conponentVkAutoPost = null,
            componentBoxAutoPostVk = null,
            componentBoxAutoPostVkRepost = null,
            componentBoxAutoPostVkName = null,
            componentBoxAutoPostVkName2 = null,
            componentBoxAutoPostVkInput = null,
            componentAutoPostFB = null,
            componentFBdetail = null,
            componentModalBoxImage = null,
            componentImagesFb = (
                <div>
                    <button className="addImage" onClick={(e) => this.openModalImage(e)} type="button">
                        <i className="fa fa-file-image-o fa-2x" aria-hidden="true"></i>
                        {this.props._t.t('Добавить изображение')}
                    </button>
                </div>
            ),
            componentCall = null;
        if (this.state.FBAutoPost.image !== '') {
            componentImagesFb = (
                <div>
                    <span>{this.props._t.t('Изображение')}</span>
                    <div className="boxImages">
                        <div style={{ height: '100%', width: '0px', display: 'inline-block', verticalAlign: 'middle' }}></div>
                        <img src={hostForLocation.getHostName().cms + this.state.FBAutoPost.image} alt="" />
                        <div onClick={(e) => this.deleteImage(e)} className="deleteImage">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.statusShowModalBoxImage === true) {
            componentModalBoxImage = <ModalImage _t={this.props._t} token={this.props.token} />;
        }
        if (this.state.FBAutoPost.status === true) {
            componentFBdetail = (
                <div className="boxAutoPost">
                    <div>
                        <div>
                            <label htmlFor="link">{'Ссылка'}</label>
                            <input
                                defaultValue={this.state.FBAutoPost.link}
                                onChange={(e) => this.onChangeFBLInk(e)}
                                id="link"
                                style={{ width: '100%' }}
                                type="text"
                            />
                        </div>
                        <div>
                            <label htmlFor="title">{'Заголовок'}</label>
                            <input
                                defaultValue={this.state.FBAutoPost.title}
                                onChange={(e) => this.onChangeFBTitle(e)}
                                id="title"
                                style={{ width: '100%' }}
                                type="text"
                            />
                        </div>
                        {/*<div>*/}
                        {/*<label htmlFor="discription">{lang.getState().pages.settings.auth.discription}</label>*/}
                        {/*<textarea defaultValue={this.state.FBAutoPost.discriptions} onChange={this.onChangeFBDiscrip} id="discription"></textarea>*/}
                        {/*</div>*/}
                        {/*{componentImagesFb}*/}
                    </div>
                </div>
            );
        }
        if (this.state.statusFb === true) {
            componentAutoPostFB = (
                <div className="boxSelectAutoPost">
                    <input
                        checked={this.state.FBAutoPost.status}
                        onChange={(e) => this.onChangeAutoPostFb(e)}
                        id="selectAutopostFb"
                        type="checkbox"
                    />
                    <label htmlFor="selectAutopostFb">{this.props._t.t('Автопост')}</label>
                </div>
            );
        }
        if (this.state.VKAutoPost.statusRepost === true) {
            componentBoxAutoPostVkRepost = (
                <div>
                    <label htmlFor="idPost">
                        {this.props._t.t('ID поста')}
                        <div className="boxInfo version3">
                            ?
                            <div className="fullTop">
                                {/*<div className="arrow-left"></div>*/}
                                <h6>{this.props._t.t('ID поста')}</h6>
                                <div id="tultip1"></div>
                            </div>
                        </div>
                    </label>
                    <input
                        id="idPost"
                        defaultValue={this.state.VKAutoPost.valueRepost.id}
                        onChange={(e) => this.onChangeVkInputReport(e)}
                        style={{ width: '100%' }}
                        type="text"
                    />
                </div>
            );
        }
        if (this.state.VKAutoPost.statusOfNameUser === true) {
            var text = this.props._t.t(
                'Cписок объектов, приложенных к записи, в формате:' +
                    '|' +
                    '<ty' +
                    'pe><own' +
                    'er_id>_<med' +
                    'ia_id>,<ty' +
                    'pe><own' +
                    'er_id>_<med' +
                    'ia_id>' +
                    '|' +
                    'Например: photo100172_166443618, photo-1_265827614' +
                    '|' +
                    'Также может быть указана ссылка на внешнюю страницу, которую Вы хотите разместить в записи, например: ' +
                    'photo66748_265827614,http://habrahabr.ru'
            );
            var textAfter = '';
            var textArr = [];
            var index = 0;
            for (var i = 0; i < text.length; i++) {
                if (text[i] !== '|' && i < text.length - 1) {
                    textAfter = textAfter + text[i];
                }
                if (text[i] === '|') {
                    index = index + 1;
                    textArr.push(textAfter);
                    textAfter = '';
                }
                if (i === text.length - 1) {
                    textAfter = textAfter + text[i];
                    textArr.push(textAfter);
                    textAfter = '';
                }
                // console.log(">>>>>",textArr, index)
            }
            var componentNodeText = textArr.map((prop, id) => {
                return <p key={id}>{prop}</p>;
            });
            componentBoxAutoPostVkName = (
                <div>
                    <label htmlFor="app">
                        {'ID поста'}
                        <div className="boxInfo version3">
                            ?
                            <div className="fullTop">
                                {/*<div className="arrow-left"></div>*/}
                                <h6>{this.props._t.t('ID поста')}</h6>
                                <div id="tultip2">{componentNodeText}</div>
                            </div>
                        </div>
                    </label>
                    <input
                        id="app"
                        defaultValue={this.state.VKAutoPost.app}
                        onChange={(e) => this.onChangeVkInputNameApp(e)}
                        style={{ width: '100%' }}
                        type="text"
                    />
                </div>
            );
            componentBoxAutoPostVkName2 = (
                <div>
                    <label htmlFor="message">{this.props._t.t('Сообщение')}</label>
                    <textarea
                        id="message"
                        defaultValue={this.state.VKAutoPost.message}
                        onChange={(e) => this.onChangeVkInputNameMessage(e)}
                    ></textarea>
                </div>
            );
        }
        if (this.state.VKAutoPost.status === true) {
            conponentVkAutoPost = (
                <div>
                    <div>
                        <label htmlFor="post">{this.props._t.t('Репост')}</label>
                        <input
                            id="post"
                            checked={this.state.VKAutoPost.statusRepost}
                            onChange={(e) => this.onChangeRadioVKRepost(e)}
                            name="select"
                            type="radio"
                        />
                        {componentBoxAutoPostVkRepost}
                    </div>
                    <div>
                        <label htmlFor="nameUser">{this.props._t.t('От имени пользователя')}</label>
                        <input
                            id="nameUser"
                            checked={this.state.VKAutoPost.statusOfNameUser}
                            onChange={(e) => this.onChangeRadioVKName(e)}
                            name="select"
                            type="radio"
                        />
                        {componentBoxAutoPostVkName}
                        {componentBoxAutoPostVkName2}
                    </div>
                </div>
            );
            componentBoxAutoPostVk = <div className="boxAutoPost">{conponentVkAutoPost}</div>;
        }
        if (this.state.statusVK === true) {
            componentBoxAutoPostVkInput = (
                <div className="boxSelectAutoPost">
                    <input
                        id="selectAutopost"
                        checked={this.state.VKAutoPost.status}
                        onChange={(e) => this.onChangeAutoPostVk(e)}
                        type="checkbox"
                    />
                    <label htmlFor="selectAutopost">{this.props._t.t('Автопост')}</label>
                </div>
            );
        }
        if (this.state.statusPass === true) {
            PassComponent = (
                <div className="passInput">
                    <span>Пароль </span>
                    <input defaultValue={this.state.inputPass} onChange={(e) => this.onChangeInputPass(e)} type="text" />
                </div>
            );
            styleSectionsHeight = '107px';
        }
        if (this.state.statusCall === true) {
            // console.log('auth', this.state.inputCall / 60 / 60 /24, this.state.inputCall);
            componentCall = (
                <div className="boxSelectAutoPost">
                    <label htmlFor="textCall">{this.props._t.t('запомнить звонок на (дни)')}</label>
                    <br />
                    <input
                        style={{ marginBottom: '20px' }}
                        id="textCall"
                        defaultValue={this.state.inputCall / 60 / 60 / 24}
                        onChange={(e) => this.onChangeCall(e)}
                        type="number"
                    />
                </div>
            );
        }
        if (this.state.statusSettingsAuth === true) {
            if (this.state.statusSocial === true) {
                componentSettingsAuth = (
                    <div className={this.props.className}>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Обязательная СМС авторизация')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeSMS(e)} checked={this.state.statusSms} />
                            </div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-phone fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Авторизация по звонку')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onCheckCall(e)} checked={this.state.statusCall} />
                            </div>
                            <div
                                style={{
                                    clear: 'both',
                                    float: 'none',
                                    width: '100%',
                                    height: '0px',
                                    padding: '0px',
                                    margin: '0px',
                                    display: 'block',
                                }}
                            ></div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-square-o fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Кнопка "Войти в интернет"')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeButton(e)} checked={this.state.statusButton} />
                            </div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-vk fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('ВКонтакте')}</span>
                                {componentBoxAutoPostVkInput}
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeVK(e)} checked={this.state.statusVK} />
                            </div>
                            {componentBoxAutoPostVk}
                            <div
                                style={{
                                    clear: 'both',
                                    float: 'none',
                                    width: '100%',
                                    height: '0px',
                                    padding: '0px',
                                    margin: '0px',
                                    display: 'block',
                                }}
                            ></div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-facebook fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Facebook')}</span>
                                {componentAutoPostFB}
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeFb(e)} checked={this.state.statusFb} />
                            </div>
                            {componentFBdetail}
                            <div
                                style={{
                                    clear: 'both',
                                    float: 'none',
                                    width: '100%',
                                    height: '0px',
                                    padding: '0px',
                                    margin: '0px',
                                    display: 'block',
                                }}
                            ></div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-odnoklassniki fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Одноклассники')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeOk(e)} checked={this.state.statusOk} />
                            </div>
                        </div>
                        <div className="paramsItem" style={{ height: styleSectionsHeight }}>
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-lock fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Авторизация по паролю')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangePass(e)} checked={this.state.statusPass} />
                            </div>
                            {PassComponent}
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="paramsItem paramsInput inlineElem">
                            <div>
                                <div>
                                    <span>{this.props._t.t('Период интернет сессии (минуты)')}</span>
                                </div>
                                <div>
                                    <input
                                        placeholder={this.props._t.t('минуты')}
                                        onChange={(e) => this.onChangeSessionPeriod(e)}
                                        defaultValue={this.state.sessionPeriod}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>{this.props._t.t('Период запроса авторизации (минуты)')}</span>
                                </div>
                                <div>
                                    <input
                                        placeholder={this.props._t.t('минуты')}
                                        onChange={(e) => this.onChangeAuthPeriod(e)}
                                        defaultValue={this.state.authPeriod}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>{this.props._t.t('Ограничения по трафику (МБ)')}</span>
                                </div>
                                <div>
                                    <input
                                        placeholder={this.props._t.t('мегабайты')}
                                        onChange={(e) => this.onChangeTraficVolume(e)}
                                        value={this.state.traficVolume}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>{this.props._t.t('Ссылка редиректа')}</span>
                                </div>
                                <div>
                                    <input
                                        style={this.state.colorBorderRedirectUrl}
                                        placeholder="https://mysite.ru"
                                        onChange={(e) => this.onChangeRedirectUrl(e)}
                                        value={this.state.redirectUrl}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="paramsItem paramsInput">
                            <div style={{ float: 'none', width: '100%', textAlign: 'center' }}>
                                <span>
                                    {this.props._t.t('Для расширенных настроек авторизации перейдите в')}{' '}
                                    <a onClick={(e) => this.onClickSettings(e)}>{this.props._t.t('Конструктор')}.</a>
                                </span>
                            </div>
                            {/*<div>*/}
                            {/*/!*<span>Настроить</span>*!/*/}
                            {/*</div>*/}
                            {/*<div></div>*/}
                        </div>
                    </div>
                );
            } else if (this.state.statusSocial === false) {
                componentSettingsAuth = (
                    <div className={this.props.className}>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Обязательная СМС авторизация')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeSMS(e)} checked={this.state.statusSms} />
                            </div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-phone fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Авторизация по звонку')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onCheckCall(e)} checked={this.state.statusCall} />
                            </div>
                            <div
                                style={{
                                    clear: 'both',
                                    float: 'none',
                                    width: '100%',
                                    height: '0px',
                                    padding: '0px',
                                    margin: '0px',
                                    display: 'block',
                                }}
                            ></div>
                        </div>
                        <div className="paramsItem">
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-square-o fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Кнопка "Войти в интернет"')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangeButton(e)} checked={this.state.statusButton} />
                            </div>
                        </div>
                        <div className="paramsItem" style={{ height: styleSectionsHeight }}>
                            <div>
                                <div className="iconBox">
                                    <i className="fa fa-lock fa-2x" aria-hidden="true"></i>
                                </div>
                                <span>{this.props._t.t('Авторизация по паролю')}</span>
                            </div>
                            <div>{/*<span>Настроить</span>*/}</div>
                            <div>
                                <Switch onChange={(e) => this.onChangePass(e)} checked={this.state.statusPass} />
                            </div>
                            {PassComponent}
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="paramsItem paramsInput inlineElem">
                            <div>
                                <div>
                                    <span>{this.props._t.t('Период интернет сессии (минуты)')}</span>
                                </div>
                                <div>
                                    <input
                                        placeholder={this.props._t.t('минуты')}
                                        onChange={(e) => this.onChangeSessionPeriod(e)}
                                        defaultValue={this.state.sessionPeriod}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>{this.props._t.t('Период запроса авторизации (минуты)')}</span>
                                </div>
                                <div>
                                    <input
                                        placeholder={this.props._t.t('минуты')}
                                        onChange={(e) => this.onChangeAuthPeriod(e)}
                                        defaultValue={this.state.authPeriod}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div>
                                <div style={{ height: '40px', paddingTop: '20px' }}>
                                    <span>{this.props._t.t('Ограничения по трафику (МБ)')}</span>
                                </div>
                                <div>
                                    <input
                                        placeholder={this.props._t.t('мегабайты')}
                                        onChange={(e) => this.onChangeTraficVolume(e)}
                                        defaultValue={this.state.traficVolume}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>{this.props._t.t('Ссылка редиректа')}</span>
                                </div>
                                <div>
                                    <input
                                        style={this.state.colorBorderRedirectUrl}
                                        placeholder="https://mysite.ru"
                                        onChange={(e) => this.onChangeRedirectUrl(e)}
                                        value={this.state.redirectUrl}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="paramsItem paramsInput">
                            <div style={{ float: 'none', width: '100%', textAlign: 'center' }}>
                                <span>
                                    {this.props._t.t('Для расширенных настроек авторизации перейдите в')}{' '}
                                    <a onClick={(e) => this.onClickSettings(e)}>{this.props._t.t('Конструктор')}.</a>
                                </span>
                            </div>
                            {/*<div>*/}
                            {/*/!*<span>Настроить</span>*!/*/}
                            {/*</div>*/}
                            {/*<div></div>*/}
                        </div>
                    </div>
                );
            }
        } else if (this.state.statusSettingsAuth === false) {
            componentSettingsAuth = (
                <div className={this.props.className}>
                    <div className="noSettingsAuthBox">
                        <span>
                            {this.props._t.t(
                                'На данной странице настроена более сложная схема авторизации. Для ее редактирования воспользуйтесь'
                            )}{' '}
                            <a onClick={(e) => this.onClickSettings(e)}>{this.props._t.t('конструктором ')}</a>
                            {this.props._t.t('страниц. Также вы можете')}{' '}
                            <a onClick={(e) => this.onReset(e)}>{this.props._t.t('сбросить')}</a>{' '}
                            {this.props._t.t('установленные параметры и настроить заново.')}
                        </span>
                    </div>
                </div>
            );
        }
        // console.log('sdsd>>>>>>>', lang.getState().pages.settings.auth.textNotAuth.third);
        return (
            <div id="authorizationBox">
                {componentSettingsAuth}
                {componentModalBoxImage}
            </div>
        );
    }
}

export default Authorization;


