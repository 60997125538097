import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import createReportParams from '../../../xlsx/tools/create_report_params';
import { DiagramsContext } from '../../index';
import styled from 'styled-components/macro';
import PdfPage from './pdf_page';
import PdfContent from './pdf_content';
import { getIterations } from '../diagram';
import Group from "../group";
import GroupPdf from "../group_pdf_custom_ssection";
import CustomControl from "../custom_controls/CustomControl";
import GroupTitle from "../group_title";
import PdfContentTemp from "./pdf_content_temp";




const PagesListCustomSection = React.memo(() => {
    const props = useContext(DiagramsContext);
    const { t } = useTranslation();
    const [platePositions, setPlatePositions] = useState({});
    const [reportPositions, setReportPositions] = useState({});
    const [report, setReport] = useState([]);
    const [multiplePages, setMultiplePages] = useState(false);
    const [counter, setCounter] = useState(0);
    const [pages, setPages] = useState([]);
    const [arrayDiagrams, setArrayDiagrams] = useState([]);
    const [groupByGroupId, setGroupByGroupId] = useState(null);
    const {
        groups,
        lang,
        cabinetMode,
        dataViewsByGroupID,
        fullScreenId,
        currentSubSection,
        diagramOptionsByID,
        currentSection,
        sectionsByKeyName,
        permanentCloudData
    } = useContext(DiagramsContext);

    useEffect(() => {
        if (Object.keys(props.reportParams).length !== 0) {
            const report = createReportParams('', props.reportParams, props.input_parameter_list, props.lang, props.locationsСatalog, {
                currentSection: props.currentSection,
                currentSubSection: props.currentSubSection,
                sectionsByKeyName: props.sectionsByKeyName,
                projectLocationsById: props.projectLocationsById,
                outdoorAdSpacesById: props.outdoorAdSpacesById,
                customParamsSet: props.customParamsSet,
            });

            setReport(report);
        }
    }, [
        props.reportParams,
        props.input_parameter_list,
        props.locationsСatalog,
        props.projectLocationsById,
        props.outdoorAdSpacesById,
        props.lang,
    ]);

    useEffect(() => {
        if (Object.keys(platePositions).length > 0) {
            let arr = []
            let arrayGlobal = []
            let _array2 = []
            let _groupByGroupId = {}
            permanentCloudData.custom_dataViews.forEach((item, i) => {
                if (item.layout_item) {
                    arr.push(item);
                }

            });
            const sortDataView = (a, b) => {
                if (platePositions[a.group.id] && platePositions[b.group.id]) {
                    if (platePositions[a.group.id][a.data_view_id].position < platePositions[b.group.id][b.data_view_id].position ) {
                        return -1;
                    }
                    if (platePositions[a.group.id][a.data_view_id].position > platePositions[b.group.id][b.data_view_id].position) {
                        return 1;
                    }
                }
            
                return 0;
            }
            arr.sort(sortDataView)


            groups.groups.forEach((item2, i2) => {
                let _arrayTemp = []
                arr.forEach((item, i) => {
                    if (item.group.id === item2.id) {
                        _arrayTemp.push(item)
                    }
                })
                _groupByGroupId[item2.id] = item2
                _array2.push(_arrayTemp)

            })

            let shift = 1200
            let _array = [[[]]]

            _array2.forEach((item, i) => {
                item.forEach((item2, i2) => {


                    if (platePositions[item2.group.id][item2.data_view_id].position < shift) {

                        _array[_array.length - 1][_array[_array.length - 1].length - 1].push(item2);
                    }
                    else {
                        _array.push([[]])
                        _array[_array.length - 1][_array[_array.length - 1].length - 1].push(item2);
                        shift += 1500
                    }

                })
                if (i < _array2.length - 1) {
                    _array.push([])
                    shift += 1500
                    _array[_array.length - 1].push([])
                }

            })

            setGroupByGroupId({..._groupByGroupId})
            setArrayDiagrams([..._array])
        }



    }, [permanentCloudData, groups, platePositions])

    const getReportItemEndPosition = (data) => {
        const pos = reportPositions;
        pos[data.id] = data;
        setReportPositions({ ...pos });
    };

    const getPlateEndPosition = (data) => {
        if (!data) return;

        const pos = platePositions;
        if (!pos[data.groupId]) {
            pos[data.groupId] = {};
        }

        pos[data.groupId][data.dataView.data_view_id] = data;
        setPlatePositions({ ...pos });
    };

    const getFlatStruct = () => {
        if (props.groups === undefined || !props.reportData) return [];

        const flatStruct = [];
        let index = -1;
        let selected = '';

        report.forEach((item, i) => {
            if (item[0] === t('Выбранные локации') || item[0] === t('Выбранные рекламные поверхности')) {
                index = i;
                selected = item[0];
            }
        });

        if (index !== -1) {
            const reportParams = report.filter((item, i) => i < index).filter((item) => item.length !== 0);
            const reportLocations = report.filter((item, i) => i > index);

            flatStruct.push({
                groupId: 'Report Params',
                type: 'reportTitle',
                title: t('Параметры отчета'),
            });

            reportParams.forEach((item, i) => {
                flatStruct.push({
                    type: 'reportString',
                    item,
                    id: `${item[0]} - ${i}`,
                });
            });

            flatStruct.push({
                groupId: 'Report Locations',
                type: 'reportTitle',
                title: selected,
            });

            reportLocations.forEach((item, i) => {
                flatStruct.push({
                    type: 'reportString',
                    item,
                    id: `${item[0]} - ${i}`,
                });
            });
        }
        props.groups.groups.forEach((item) => {
            const diagramsGroup = props.dataViewsByGroupID[item.id];
            const groupId = item.id;
            let title = item.translations[props.lang].name;
            flatStruct.push(
                item.custom_key_name === null || item.custom_key_name === ''
                    ? {
                        groupId,
                        type: 'groupTitle',
                        title,
                    }
                    : null
            );
            diagramsGroup &&
            diagramsGroup.forEach((dataView, indx, arr) => {
                const report = props.reportData[dataView.data_view_id];
                let dataRecieved = false;

                if (report && report?.response && report?.isSuccess) {
                    dataRecieved = true;
                } else if (props.reportData && report && report?.response === undefined) {
                    dataRecieved = true;
                } else if (report && !report.isSuccess && report.error) {
                    dataRecieved = true;
                } else {
                    dataRecieved = false;
                }



            });
        });

        if (!multiplePages && flatStruct.filter((item) => item.type === 'diagram').filter((item) => !item.dataRecieved).length === 0) {
            // console.log('>>>>>>>>', flatStruct);
            setTimeout(() => {
                setMultiplePages(true);
            }, 6000);
        }

        return flatStruct;
    };

    const flatStruct = useMemo(() => {
        return getFlatStruct();
    }, [props.groups]);

    useEffect(() => {


        if (!multiplePages || (pages.length > 1 && counter > 10)) return;
        const pagesArr = [[]];
        let count = 0;
        const groupsHeights = {};
        const pos = platePositions;
        if (Object.keys(pos).length !== 0) {
            Object.keys(pos).forEach((groupId) => {
                const sorted = Object.keys(pos[groupId])
                    .map((id) => pos[groupId][id])
                    .sort((a, b) => a.position - b.position);
                const groupHeight = sorted[sorted.length - 1].position - sorted[0].position + sorted[0].height;
                groupsHeights[groupId] = groupHeight;
            });
        }
        flatStruct.forEach((element, i, arr) => {
             if (element.type === 'groupTitle') {
                if (
                    pagesArr[pagesArr.length - 1].length === 0 ||
                    // (
                    count < props.WORK_HEIGHT / 2

                ) {

                    pagesArr[pagesArr.length - 1].push(element);
                    count = count + 50;
                } else {
                    pagesArr.push([element]);
                    count = 50;
                }
            } else if (element.type === 'reportTitle') {
                pagesArr[pagesArr.length - 1].push(element);
                count = count + 50;
            } else if (element.type === 'reportString') {
                if (count < props.WORK_HEIGHT && reportPositions[element.id]) {
                    pagesArr[pagesArr.length - 1].push(element);
                    // console.log('>>>>> ', reportPositions[element.id], element);
                    count = count + reportPositions[element.id].height;
                } else if (count >= props.WORK_HEIGHT && reportPositions[element.id]) {
                    pagesArr.push([element]);
                    count = reportPositions[element.id].height;
                }
            }


        });

        arrayDiagrams.forEach((item, i) => {
            if (i === 0) {
                pagesArr[pagesArr.length - 1].push(item);
            }
            else {
                pagesArr.push([item])
            }
        })


        setPages([...pagesArr]);
        setCounter(counter + 1);
    }, [platePositions, flatStruct, multiplePages, reportPositions]);

    const infoText = t('Для добавления диаграммы из любого раздела в My Dashboard, вызовите контекстное меню необходимой диаграммы.');
    const single = useMemo(() => {
        // console.log('><>!!!><><', groups)

        return (
            <>

                <Wrapper>
                    <button onClick={() => setMultiplePages(true)}>Paginate</button>

                    {flatStruct.map((item, i) => {
                        return (
                            <PdfContent

                                key={`${i} <>++- ${item.type}`}
                                getPlateEndPosition={getPlateEndPosition}
                                getReportItemEndPosition={getReportItemEndPosition}
                                {...props}
                                {...item}
                            />
                        );
                    })}
                    { groups && groups.groups ?
                        groups.groups.map((group, i) => {

                            const editable = sectionsByKeyName[currentSection].subsections
                                .filter((item) => item.key_name === currentSubSection)[0]
                                .data_view_groups.filter((item) => item.id === group.id)[0].editable;

                            const generateGroupContent = () => {
                                if (dataViewsByGroupID[group.id] && (group.custom_key_name === null || group.custom_key_name === '')) {
                                    return <GroupPdf
                                        getPlateEndPosition={getPlateEndPosition}
                                        group={dataViewsByGroupID[group.id]}
                                        groupID={group.id}
                                        editable={editable}
                                        i={i}
                                        {...props}
                                    />;
                                } else if (group.custom_key_name) {
                                    return <CustomControl group={group} />;
                                } else if (!dataViewsByGroupID[group.id] && currentSection === 'custom_section') {
                                    return <Info>{infoText}</Info>;
                                } else if (!dataViewsByGroupID[group.id]) {
                                    return null;
                                } else {
                                    return null;
                                }
                            };

                            const showGroup =
                                fullScreenId === null
                                    ? true
                                    : fullScreenId !== null &&
                                    dataViewsByGroupID[group.id] &&
                                    dataViewsByGroupID[group.id].map((dataView) => dataView.data_view_id).some((id) => id === fullScreenId)
                                        ? true
                                        : group.custom_key_name
                                            ? true
                                            : false;
                            let title = lang === 'ru' ? group.translations['ru'].name : group.translations['en'].name;

                            return showGroup ? (
                                <Container cabinetMode={cabinetMode} key={`${group.id}-${i}-newLine`}>
                                    {(group.custom_key_name === null || group.custom_key_name === '') && fullScreenId === null ? (
                                        <GroupTitle title={title} group={group} editable={false} />
                                    ) : null}
                                    {generateGroupContent()}
                                </Container>
                            ) : null;
                        }) : null
                    }
                </Wrapper>

            </>

        );
    }, [flatStruct.length, reportPositions]);

    const multi = useMemo(() => {
        return pages.map((page, i) => {
            return (
                <PdfPage
                    currentSubSection={props.currentSubSection}
                    currentSection={props.currentSection}
                    sectionsByKeyName={props.sectionsByKeyName}
                    logo={props.white_label_settings.small_logo_url}
                    lang={props.lang}
                    key={`${i} - ${page[0].type}`}
                    id={`pdf-page-${i}`}
                >
                    <Wrapper>
                        {page.map((item, j) => {

                            return (
                                <>
                                    <PdfContentTemp
                                        key={`${j} == ${i}-++ ${item.type}`}
                                        getPlateEndPosition={getPlateEndPosition}
                                        getReportItemEndPosition={getReportItemEndPosition}
                                        {...props}
                                        {...item}

                                    />
                                    {
                                        item.length > 0 ?
                                        item.map((item2,i2) => {
                                            const generateGroupContent = () => {
                                                if (item2.length > 0) {
                                                    return <Group group={item2}
                                                        // groupID={group.id}
                                                        //           editable={editable}
                                                    />;
                                                }

                                            };
                                            let title = ''
                                            let isShowTitle = false

                                            if (i > 0) {
                                                let preElement = pages[i - 1][pages[i - 1].length - 1][0][0]
                                                let currentElement = page[0][0][0]
                                                if (preElement.group.id !== currentElement.group.id) {
                                                    isShowTitle = true
                                                }
                                                // console.log('>>>>>>>>', j, pages[i - 1], page)
                                            }
                                            if (i2 === 0 && i === 0) {
                                                isShowTitle = true

                                            }
                                            title = lang === 'ru' ? groupByGroupId[item2[0].group.id].translations['ru'].name : groupByGroupId[item2[0].group.id].translations['en'].name;

                                            return (<Container cabinetMode={cabinetMode} key={`${i2 - 1}-${i2}-newLine`}>
                                                {isShowTitle? (
                                                    <GroupTitle title={title}
                                                                // group={group}
                                                                modeCabinet='pdf'
                                                                margin={'30px 0px 0 0px'}
                                                                editable={false}
                                                    />
                                                ) : null}
                                                {generateGroupContent()}
                                            </Container>)

                                        }) : null

                                    }
                                </>
                            );
                        })}


                    </Wrapper>
                </PdfPage>
            );
        });
    }, [multiplePages, pages]);
    // }, [platePositions, multiplePages, pages, reportPositions]);

    if (multiplePages) {
        // console.log('>>>>> pdf_created ');
        return (
            <>
                <SignalElement id={'pdf_created'} />
                {multi}
            </>
        );
    } else {
        return single;
    }
});

export default PagesListCustomSection;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding-left: 1px;
`;

const SignalElement = styled.div`
    width: 0px;
    height: 0px;
    position: absolute;
`;

const Container = styled.section`
    box-sizing: border-box;
    height: ${(p) => (p.fullScreenId === null ? 'auto' : '100%')};
`;

const Info = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;