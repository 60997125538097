import React from 'react';
import styled from 'styled-components/macro';
import _t from 'i18next';
import { Icon } from '@fluentui/react/lib/Icon';

const Container = styled.div`
  display: flex;
  margin: 5px 0;
  /* border-top: 1px solid #cbc7c5; */
`;

const SelectButton = styled.div`
  cursor: pointer;
  color: rgb(50, 136, 86);
  border-bottom: 1px solid transparent;
  /* font-family: ProximaNova; */
  font-size: 11px;
  margin-right: 10px;
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: 100%;
  &:hover {
    border-bottom: 1px solid rgb(50, 136, 86);
  }
`;

export default ({ onSelectAllClick, onClearAllClick, onSort, statusSort }) => {
    return (
        <Container>
            <SelectButton onClick={onSelectAllClick}>{_t.t('выделить все')}</SelectButton>
            <SelectButton onClick={onClearAllClick}>{_t.t('очистить')}</SelectButton>
            <SelectButton onClick={onSort}>{_t.t('отсортировать')} {statusSort === 'not' ? null : <Icon iconName={statusSort === "firstToEnd"?"SortUp" : "SortDown"} />} </SelectButton>
        </Container>
    );
};
