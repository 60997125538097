import { ChromePicker } from 'react-color';
import React, { Component } from 'react';
import AppActions from '../actions/actions';
import AppStore from '../stores/store';
import hostForLocation from '../../../hostnames/hostname'
var jq = require('jquery');

class Performance extends Component {
    state = {
        displayColorPickerButton: false,
        displayColorPickerTextButton: false,
        displayColorPickerBackground: false,
        displayColorPickerBackgroundColor: false,
        displayColorPickerColorText: false,
        backgroundColorButton: {
            r: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[0],
            g: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[1],
            b: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[2],
            a: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[3],
        },
        // valueText: AppStore.getState().authButtonText,
        backgroundColorBackground: {
            r: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[0],
            g: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[1],
            b: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[2],
            a: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[3],
        },
        backgroundColorBackgroundWelcome: {
            r: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[0],
            g: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[1],
            b: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[2],
            a: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[3],
        },
        colorText: {
            r: AppStore.getState().colorValueText.slice(5, -1).split(',')[0],
            g: AppStore.getState().colorValueText.slice(5, -1).split(',')[1],
            b: AppStore.getState().colorValueText.slice(5, -1).split(',')[2],
            a: AppStore.getState().colorValueText.slice(5, -1).split(',')[3],
        },
        statusCheckedAuthDisplayMode: AppStore.getState().authDisplayMode,
        urlImageBackground: AppStore.getState().urlImageBackground,
        statusSelectBackgroundColorWelcomGlobalPage: AppStore.getState().statusSelectBackgroundColorWelcomGlobalPage,
        urlImageLogo: AppStore.getState().urlImageLogo,
        urlImageGraphic: AppStore.getState().urlImageGraphic,
        statusPerformens: AppStore.getState().statusPerformens,
        valueText: AppStore.getState().valueText,
    };
    internalState = {
        statusChecked: AppStore.getState().authDisplayMode,
    };
    _onChange() {
        this.setState({
            backgroundColorButton: {
                r: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[0],
                g: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[1],
                b: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[2],
                a: AppStore.getState().authButtonBackgroundColor.slice(5, -1).split(',')[3],
            },
            // valueText: AppStore.getState().authButtonText,
            backgroundColorBackground: {
                r: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[0],
                g: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[1],
                b: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[2],
                a: AppStore.getState().authBackgroundColor.slice(5, -1).split(',')[3],
            },
            backgroundColorBackgroundWelcome: {
                r: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[0],
                g: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[1],
                b: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[2],
                a: AppStore.getState().backgroundColorWelcomGlobalPage.slice(5, -1).split(',')[3],
            },
            colorText: {
                r: AppStore.getState().colorValueText.slice(5, -1).split(',')[0],
                g: AppStore.getState().colorValueText.slice(5, -1).split(',')[1],
                b: AppStore.getState().colorValueText.slice(5, -1).split(',')[2],
                a: AppStore.getState().colorValueText.slice(5, -1).split(',')[3],
            },
            statusCheckedAuthDisplayMode: AppStore.getState().authDisplayMode,
            urlImageBackground: AppStore.getState().urlImageBackground,
            statusSelectBackgroundColorWelcomGlobalPage: AppStore.getState().statusSelectBackgroundColorWelcomGlobalPage,
            urlImageLogo: AppStore.getState().urlImageLogo,
            urlImageGraphic: AppStore.getState().urlImageGraphic,
            statusPerformens: AppStore.getState().statusPerformens,
            valueText: AppStore.getState().valueText,
        });
        this.internalState.statusChecked = AppStore.getState().authDisplayMode;
    }
    componentDidMount() {
        AppStore.addChangeStoreModuleListener(this._onChange.bind(this));
        // document['reloadPages'] = function (e) {
        //     alert('hello!!');
        // }
    }
    componentWillUnmount() {
        AppStore.removeChangeStoreModuleListener(this._onChange.bind(this));
    }
    handleClick(e) {
        console.log('per');
        if (e.target === document.getElementById('buttomColorButtom')) {
            this.setState({
                displayColorPickerButton: !this.state.displayColorPickerButton,
                displayColorPickerTextButton: false,
                displayColorPickerBackground: false,
                displayColorPickerBackgroundColor: false,
                displayColorPickerColorText: false,
            });
        } else if (e.target === document.getElementById('buttomColorButtomText')) {
            this.setState({
                displayColorPickerButton: false,
                displayColorPickerTextButton: !this.state.displayColorPickerTextButton,
                displayColorPickerBackground: false,
                displayColorPickerBackgroundColor: false,
                displayColorPickerColorText: false,
            });
        } else if (e.target === document.getElementById('buttomColorButtomBackground')) {
            this.setState({
                displayColorPickerButton: false,
                displayColorPickerTextButton: false,
                displayColorPickerBackground: !this.state.displayColorPickerBackground,
                displayColorPickerBackgroundColor: false,
            });
        } else if (e.target === document.getElementById('BackgroundColor')) {
            this.setState({
                displayColorPickerButton: false,
                displayColorPickerTextButton: false,
                displayColorPickerBackground: false,
                displayColorPickerBackgroundColor: !this.state.displayColorPickerBackgroundColor,
                displayColorPickerColorText: false,
            });
        } else if (e.target === document.getElementById('colorText')) {
            this.setState({
                displayColorPickerButton: false,
                displayColorPickerTextButton: false,
                displayColorPickerBackground: false,
                displayColorPickerBackgroundColor: false,
                displayColorPickerColorText: !this.state.displayColorPickerColorText,
            });
        }
    }
    onCheck(e) {
        // console.log('perf', e);
        if (this.internalState.statusChecked === true) {
            this.internalState.statusChecked = false;
        } else if (this.internalState.statusChecked === false) {
            this.internalState.statusChecked = true;
        }
        AppActions.onCheckMode(this.internalState.statusChecked);
    }
    hideColorPicker(e) {
        // console.log('per', jq(e.target).parents().filter('#boxColorPicker').length)
        if (
            e.target !== document.getElementById('buttomColorButtom') &&
            e.target !== document.getElementById('boxColorPicker') &&
            jq(e.target).parents().filter('#boxColorPicker').length !== 1 &&
            e.target !== document.getElementById('buttomColorButtomText') &&
            e.target !== document.getElementById('buttomColorButtomBackground') &&
            e.target !== document.getElementById('BackgroundColor') &&
            e.target !== document.getElementById('colorText')
        ) {
            this.setState({
                displayColorPickerButton: false,
                displayColorPickerTextButton: false,
                displayColorPickerBackground: false,
                displayColorPickerBackgroundColor: false,
                displayColorPickerColorText: false,
            });
        }
    }
    handleChangeColorBackgroundButton(e) {
        // console.log('per>>>>>>>>', e);
        // this.setState({
        //     backgroundColorButton: e.rgb
        // });
        var color = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        AppActions.handleChangeColorBackgroundButton(color);
    }
    handelChangeBackgroundWelcome(e) {
        var color = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        AppActions.handelChangeBackgroundWelcome(color);
    }
    handleChangeBackground(e) {
        // this.setState({
        //     backgroundColorBackground: e.rgb
        // });
        // console.log('per>>>>>>>>', e.rgb);
        var color = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        AppActions.onChangeColorAuthBackground(color);
    }
    handleChangeColorBackgroundTextButton(e) {
        this.setState({
            backgroundColorTextButton: e.rgb,
        });
    }
    onClickSettings() {
        let mainLoc = this.props.location.split('hotspot_page_settings')[0] + 'hotspot_page_settings';

        let url =
            `${hostForLocation.getHostName().pageConstructorGetshopster}/` +
            '?token=' +
            this.props.token +
            '&page_id=' +
            this.props.prop.id +
            '&back_url=' +
            mainLoc +
            '&lang=' +
            this.props.lang;
        window.location.replace(url, '_blank');
    }
    onClickSettingsNew() {
        let mainLoc = this.props.location.split('hotspot_page_settings')[0] + 'hotspot_page_settings';
        let url =
            `${hostForLocation.getHostName().pageConstructorGetshopster}/` +
            '?token=' +
            this.props.token +
            '&page_id=' +
            this.props.prop.id +
            '&back_url=' +
            mainLoc +
            '&lang=' +
            this.props.lang;
        window.location.replace(url, '_blank');
    }
    onChangeTextButtonAuth(e) {
        AppActions.onChangeTextButtonAuth(e.target.value);
    }
    openModalImage() {
        AppActions.openModalImage('perfomBackground');
    }
    openModalImageLogo() {
        AppActions.openModalImage('perfomLogo');
    }
    openModalImageGraphicBlock() {
        AppActions.openModalImage('perfomGraphicBlock');
    }
    deleteImageBackgroundWelcom() {
        AppActions.deleteImageBackgroundWelcom();
    }
    deleteColorBackgroundWelcom() {
        AppActions.deleteColorBackgroundWelcom();
    }
    deleteLogo() {
        AppActions.deleteLogo();
    }
    deleteGraphic() {
        AppActions.deleteGraphic();
    }
    onClickColorText(e) {
        var color = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        AppActions.onClickColorText(color);
    }
    onChangeText(e) {
        var dataText = e.target.value;
        var dateTextBefore = dataText.split('\n');
        // console.log('per', e.target.value, dateTextBefore);
        var dataTextOrigin = e.target.value;
        // if (dateTextBefore[dateTextBefore.length - 1].length >= 24) {
        //     dataTextOrigin + '\n';
        // }
        // if (dateTextBefore[dateTextBefore.length - 1][dateTextBefore[dateTextBefore.length - 1].length - 1] === ' ' &&
        //     dateTextBefore[dateTextBefore.length - 1][dateTextBefore[dateTextBefore.length - 1].length - 2] === ' ') {
        //     // console.log('!!!!!!!');
        //     return;
        // }
        if (dataTextOrigin.length >= 101) {
            return;
        }
        e.preventDefault();
        // console.log('per', e.target.value, dataTextOrigin);
        // console.log('per',dataTextOrigin.length, dateTextBefore);
        AppActions.onChangeText(dataTextOrigin);
        // AppActions.onChangeText(e.target.value);
    }
    render() {
        // console.log('sdsd>>>>>>>', lang.getState().pages.settings.preview.orBackground);
        // console.log('per>>>>>>>>', this.state.displayColorPickerBackgroundColor);
        var componentButtomColorPicker = null,
            componentTextButtomColorPicker = null,
            componentBackgroundColorPicker = null,
            componentBackgroundColor = null,
            componentColorText = null,
            backgroundButton = `rgba(${this.state.backgroundColorButton.r}, ${this.state.backgroundColorButton.g}, ${this.state.backgroundColorButton.b}, ${this.state.backgroundColorButton.a})`,
            backgroundButtonBackground = `rgba(${this.state.backgroundColorBackground.r}, ${this.state.backgroundColorBackground.g}, ${this.state.backgroundColorBackground.b}, ${this.state.backgroundColorBackground.a})`,
            backgroundWelcome = `rgba(${this.state.backgroundColorBackgroundWelcome.r},
            ${this.state.backgroundColorBackgroundWelcome.g}, 
            ${this.state.backgroundColorBackgroundWelcome.b},
            ${this.state.backgroundColorBackgroundWelcome.a})`,
            colorText = `rgba(${this.state.colorText.r},
            ${this.state.colorText.g}, 
            ${this.state.colorText.b},
            ${this.state.colorText.a})`;
        if (this.state.displayColorPickerColorText === true) {
            componentColorText = (
                <div id="boxColorPicker" className="boxColorPicker">
                    <ChromePicker onChange={(e) => this.onClickColorText(e)} color={this.state.colorText} />
                </div>
            );
        }
        if (this.state.displayColorPickerButton === true) {
            componentButtomColorPicker = (
                <div id="boxColorPicker" className="boxColorPicker">
                    <ChromePicker onChange={(e) => this.handleChangeColorBackgroundButton(e)} color={this.state.backgroundColorButton} />
                </div>
            );
        }
        if (this.state.displayColorPickerBackground === true) {
            componentBackgroundColorPicker = (
                <div id="boxColorPicker" className="boxColorPicker">
                    <ChromePicker onChange={(e) => this.handleChangeBackground(e)} color={this.state.backgroundColorBackground} />
                </div>
            );
        }
        if (this.state.displayColorPickerBackgroundColor === true) {
            componentBackgroundColor = (
                <div id="boxColorPicker" className="boxColorPicker">
                    <ChromePicker
                        onChange={(e) => this.handelChangeBackgroundWelcome(e)}
                        color={this.state.backgroundColorBackgroundWelcome}
                    />
                </div>
            );
        }
        var componentBackgroundImega = (
            <div>
                <button onClick={this.openModalImage}>
                    <i className="fa fa-file-image-o fa-2x" aria-hidden="true"></i>
                    {this.props._t.t('Добавить изображение')}
                </button>
                <span>{this.props._t.t('или цвет фона')}</span>
                <div className="buttonShowColorPicker" style={{ marginTop: '31px' }}>
                    <div
                        id="BackgroundColor"
                        onClick={(e) => this.handleClick(e)}
                        style={{ background: backgroundWelcome, width: '100%', height: '100%' }}
                    ></div>
                </div>
                <div
                    style={{ clear: 'both', float: 'none', width: '100%', height: '0px', padding: '0px', margin: '0px', display: 'block' }}
                ></div>
                {componentBackgroundColor}
            </div>
        );
        var componentLogo = (
            <div>
                <button onClick={(e) => this.openModalImageLogo(e)}>
                    <i className="fa fa-file-image-o fa-2x" aria-hidden="true"></i>
                    {this.props._t.t('Добавить изображение')}
                </button>
            </div>
        );
        var componentGraphicBlock = (
            <div>
                <button onClick={(e) => this.openModalImageGraphicBlock(e)}>
                    <i className="fa fa-file-image-o fa-2x" aria-hidden="true"></i>
                    {this.props._t.t('Добавить изображение')}
                </button>
            </div>
        );
        if (this.state.urlImageGraphic !== '') {
            componentGraphicBlock = (
                <div>
                    <div className="boxImage">
                        <div className="firstElementInInline"></div>
                        <img src={hostForLocation.getHostName().cms + this.state.urlImageGraphic} alt="" />
                        <div onClick={(e) => this.deleteGraphic(e)} className="deleteImage">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div
                        style={{
                            clear: 'both',
                            float: 'none',
                            width: '100%',
                            height: '0px',
                            padding: '0px',
                            margin: '0px',
                            display: 'block',
                        }}
                    ></div>
                </div>
            );
        }
        if (this.state.urlImageBackground !== '') {
            componentBackgroundImega = (
                <div>
                    <div className="boxImage">
                        <div className="firstElementInInline"></div>
                        <img src={hostForLocation.getHostName().cms + this.state.urlImageBackground} alt="" />
                        <div onClick={(e) => this.deleteImageBackgroundWelcom(e)} className="deleteImage">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div
                        style={{
                            clear: 'both',
                            float: 'none',
                            width: '100%',
                            height: '0px',
                            padding: '0px',
                            margin: '0px',
                            display: 'block',
                        }}
                    ></div>
                </div>
            );
        }
        // else if (this.state.statusSelectBackgroundColorWelcomGlobalPage === true) {
        //     componentBackgroundImega = <div>
        //         <button onClick={this.openModalImage}><i className="fa fa-file-image-o fa-2x" aria-hidden="true"></i>Добавить изображение</button>
        //         <span>или цвет фона</span>
        //         <div className="buttonShowColorPicker" style={{marginTop: '31px'}}>
        //             <div id="BackgroundColor" onClick={this.handleClick} style={{background: backgroundWelcome, width: '100%', height: '100%'}}></div>
        //             <div style={{background: 'rgba(0,0,0,.5)'}} onClick={this.deleteColorBackgroundWelcom} className="deleteImage">
        //                 <i className="fa fa-times" aria-hidden="true"></i>
        //             </div>
        //         </div>
        //         <div style={{clear: 'both', float: 'none', width: '100%', height: '0px', padding: '0px', margin: '0px', display: 'block'}}></div>
        //         {componentBackgroundColor}
        //     </div>
        // }
        if (this.state.urlImageLogo !== '') {
            componentLogo = (
                <div>
                    <div className="boxImage">
                        <div className="firstElementInInline"></div>
                        <img src={hostForLocation.getHostName().cms + this.state.urlImageLogo} alt="" />
                        <div onClick={(e) => this.deleteLogo(e)} className="deleteImage">
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div
                        style={{
                            clear: 'both',
                            float: 'none',
                            width: '100%',
                            height: '0px',
                            padding: '0px',
                            margin: '0px',
                            display: 'block',
                        }}
                    ></div>
                </div>
            );
        }
        if (this.state.statusPerformens === true) {
            // console.log('per', this.state.valueText);
            return (
                <div id="performanceBox" onClick={(e) => this.hideColorPicker(e)} className={this.props.className}>
                    <div className="itemSetingsPerform">
                        <div style={{ height: '115px' }}>
                            <span style={{ marginTop: '46px', display: 'inline-block' }} className="titleItem">
                                {this.props._t.t('Фон страницы')}
                            </span>
                        </div>
                        {componentBackgroundImega}
                    </div>
                    <div className="itemSetingsPerform">
                        <div>
                            <span style={{ marginTop: '46px', display: 'inline-block' }} className="titleItem">
                                {this.props._t.t('Логотип')}
                            </span>
                        </div>
                        {componentLogo}
                    </div>
                    <div className="itemSetingsPerform">
                        <div>
                            <span style={{ paddingTop: '17px', display: 'inline-block' }} className="titleItem">
                                {this.props._t.t('Текст')}
                            </span>
                        </div>
                        <div>
                            <textarea value={this.state.valueText} onChange={(e) => this.onChangeText(e)} max="100"></textarea>
                            <span style={{ paddingTop: '17px', height: 'inherit' }}>{this.props._t.t('Цвет текста')}</span>
                            <div className="buttonShowColorPicker">
                                <div
                                    id="colorText"
                                    onClick={(e) => this.handleClick(e)}
                                    style={{ background: colorText, width: '100%', height: '100%' }}
                                ></div>
                            </div>
                            {componentColorText}
                        </div>
                    </div>
                    <div className="itemSetingsPerform">
                        <div>
                            <span style={{ marginTop: '46px', display: 'inline-block' }} className="titleItem">
                                {this.props._t.t('Графический блок')}
                            </span>
                        </div>
                        {componentGraphicBlock}
                    </div>
                    <div className="itemSetingsPerform">
                        <div>
                            <span className="titleItem">{this.props._t.t('Цвет кнопки "ВОЙТИ В ИНТЕРНЕТ"')}</span>
                        </div>
                        <div>
                            <div className="buttonShowColorPicker">
                                <div
                                    id="buttomColorButtom"
                                    onClick={(e) => this.handleClick(e)}
                                    style={{ background: backgroundButton, width: '100%', height: '100%' }}
                                ></div>
                            </div>
                            {componentButtomColorPicker}
                        </div>
                    </div>
                    {/*<div className="itemSetingsPerform">*/}
                    {/*<div>*/}
                    {/*<span className="titleItem">Текст кнопки <br /> “ВОЙТИ В ИНТЕРНЕТ"</span>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*<input defaultValue={this.state.valueText} onChange={this.onChangeTextButtonAuth} type="text"/>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="itemSetingsPerform">*/}
                    {/*<div>*/}
                    {/*<span className="titleItem">Цвет фона панели авторизации</span>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*<div className="buttonShowColorPicker">*/}
                    {/*<div id="buttomColorButtomBackground" onClick={this.handleClick} style={{background: backgroundButtonBackground, width: '100%', height: '100%'}} ></div>*/}
                    {/*</div>*/}
                    {/*{componentBackgroundColorPicker}*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="itemSetingsPerform">*/}
                    {/*<div>*/}
                    {/*<span className="titleItem">Пропускать отображение лэндинга</span>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*<input onChange={this.onCheck} style={{marginTop: '36px'}} checked={this.state.statusCheckedAuthDisplayMode} type="checkbox"/>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    <span>
                        {this.props._t.t('Для дополнительных настроек представления перейдите в')}{' '}
                        <a onClick={(e) => this.onClickSettings(e)}>{this.props._t.t('Конструктор')}.</a>
                    </span>
                    {/*<span>{this.props._t.t('Для тестирования перейдите в')} <a onClick={(e) => this.onClickSettingsNew(e)} >{this.props._t.t('Новый конструктор')}.</a>.</span>*/}
                </div>
            );
        } else if (this.state.statusPerformens === false) {
            return (
                <div onClick={this.hideColorPicker} className={this.props.className}>
                    <span>
                        {this.props._t.t('Для дополнительных настроек представления перейдите в')}{' '}
                        <a onClick={(e) => this.onClickSettings(e)}>{this.props._t.t('Конструктор')}.</a>
                    </span>
                    {/*<span>{this.props._t.t('Для тестирования перейдите в')} <a onClick={(e) => this.onClickSettingsNew(e)} >{this.props._t.t('Новый конструктор')}.</a>.</span>*/}
                </div>
            );
        }
    }
}

export default Performance;


