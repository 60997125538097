import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';

const StIconButton = styled(IconButton)`
    color: white;
    /* border: 1px solid white; */
    :focus {
        outline: none;
    }
    i {
        font-size: 18px;
    }
`;

const ShowTablesButton = React.memo(({ onShowTablesButtonClick, isCompleteTablesShown, ...props }) => {
    const { t } = useTranslation();

    let iconProps = { iconName: isCompleteTablesShown ? 'Chart' : 'ExcelLogo16' };
    
    if (props.statusPDF) {
        iconProps = { iconName: 'PDF' };
        return (
            <TooltipHost content={t('PDF')}>
                <StIconButton iconProps={iconProps} onClick={onShowTablesButtonClick} />
                {/* <Icon iconName="ExcelLogo16" /> */}
            </TooltipHost>
        );
    }



    return (
        <TooltipHost content={isCompleteTablesShown ? t('Показать диаграммы') : t('Сохранить XLSX')}>
            <StIconButton iconProps={iconProps} onClick={onShowTablesButtonClick} />
            {/* <Icon iconName="ExcelLogo16" /> */}
        </TooltipHost>
    );
});

export default ShowTablesButton;
