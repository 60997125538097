'use strict';

// var React = require('react');
// var PropTypes = React.PropTypes;
import React, {Component} from 'react';
import AppActions from '../actions/actions';
import AppStore from '../stores/store';
import hostForLocation from '../../../hostnames/hostname'

class ModalImage extends Component {
    state ={
        dataImage: AppStore.getState().dataFiles,
        statusShowUploadPanel: AppStore.getState().statusShowUploadPanel,
        valueTitleImageUpload: AppStore.getState().valueTitleImageUpload,
        dataFilesUpload: AppStore.getState().dataFilesUpload,
        statusUploadFiles: AppStore.getState().statusUploadFiles,
        statusLoadFiles:AppStore.getState().statusLoadFiles
    }
    _onChange() {
        this.setState({
            dataImage: AppStore.getState().dataFiles,
            statusShowUploadPanel: AppStore.getState().statusShowUploadPanel,
            valueTitleImageUpload: AppStore.getState().valueTitleImageUpload,
            dataFilesUpload: AppStore.getState().dataFilesUpload,
            statusUploadFiles: AppStore.getState().statusUploadFiles,
            statusLoadFiles:AppStore.getState().statusLoadFiles
        });
    }
    componentDidMount() {
        AppStore.addChangeStoreModuleListener(this._onChange.bind(this));
    }
    componentWillUnmount() {
        AppStore.removeChangeStoreModuleListener(this._onChange.bind(this));
    }
    onHide(e) {
        if (e.target === document.getElementById('closeModalImage') || e.target === document.getElementById('backgroundModalBoxImage') || e.target === document.getElementById('closeButtonModalImage')) {
            AppActions.hideModalBoxImage();
        }
    }
    selectImage(e) {
        if (AppStore.getState().statusModeModalImageBox === 'authFB') {
            // console.log('modal', e);
            AppActions.selectImage(e.file_url);
        }
        else if (AppStore.getState().statusModeModalImageBox === 'perfomBackground') {
            // console.log('modal', e);
            AppActions.selectImagePerformBackground(e.file_url);
        }
        else if (AppStore.getState().statusModeModalImageBox === 'perfomLogo') {
            // console.log('modal', e);
            AppActions.selectImagePerformLogo(e.file_url);
        }
        else if (AppStore.getState().statusModeModalImageBox === 'perfomGraphicBlock') {
            // console.log('modal', e);
            AppActions.selectImagePerformGraphic(e.file_url);
        }
        AppActions.hideModalBoxImage();
    }
    showPanelUpload() {
        AppActions.showPanelUpload();
    }
    hidePanelUpload(e) {
        if (e.target === document.getElementById('backgroundPanelUpload') || e.target === document.getElementById('closePanelUpload') || e.target === document.getElementById('closePanelUploadFooter')) {
            AppActions.hidePanelUpload();
        }
    }
    onChangeTitleUploadImage(e) {
        AppActions.onChangeTitleUploadImage(e.target.value);
    }
    onChangeUploadInput(e) {
        // console.log('modal', e.target.files[0])
        AppActions.onChangeUploadInput(e.target.files);
    }
    sibmitUploadFile() {
        var data = {
            files: this.state.dataFilesUpload,
            access_token: this.props.token,
            mediaTitle: this.state.valueTitleImageUpload
        }
        console.log('modal' , this.state.dataFilesUpload[0].size);
        if (this.state.dataFilesUpload[0].size <= 1500000) {
            AppActions.sibmitUploadFile(data);
        }
        else if (this.state.dataFilesUpload[0].size > 1500000) {
            alert(this.props._t.t('Превышен максимальный размер изображения'))
        }
    }
    deleteItemImage(e) {
        // console.log('modal' , e);
        var data = {
            id: e.id,
            access_token: this.props.token
        }
        AppActions.deleteItemImage(data);

    }
    render() {
        console.log('modal', this.props._t);
        var componentImage = null,
            funcSelect = this.selectImage,
            funcDelete = this.deleteItemImage,
            componentUploadPanel = null,
            classNameUploadButtom = 'NotActiveButtton',
            funcSibmit = null,
            componentSpinerloaderLoadFiles = null,
            componentSpinerloaderUploadFiles = null;
        if (this.state.statusUploadFiles === true) {
            componentSpinerloaderUploadFiles = <div className="spinerLoaderUploadFiles"><div style={{height: '100%', width: '100%', margin: 'auto'}}>
                <div className="globalSpinnerLoaderSvg"></div>
            </div></div>
        }
        if (this.state.statusLoadFiles === true) {
            componentSpinerloaderLoadFiles = <div className="spinerLoaderFiles"><div className="spinerLoaderUploadFiles"><div style={{height: '100%', width: '100%', margin: 'auto'}}>
                <div className="globalSpinnerLoaderSvg"></div>
            </div>
            </div>
            </div>;
        }
        if (this.state.dataFilesUpload.length > 0) {
            classNameUploadButtom = 'ActiveButtton'
            funcSibmit = this.sibmitUploadFile.bind(this)
        }
        if (this.state.statusShowUploadPanel === true) {

            componentUploadPanel = <div onClick={(e) => this.hidePanelUpload(e)} id="backgroundPanelUpload" className="backgroundModalBoxImage">
                <div className="uploadPanel">
                    <div className="panelHeaders">
                        <h2>{this.props._t.t('Загрузка')}</h2>
                        <span id="closePanelUpload" className="closePanel">X</span>
                    </div>
                    <div className="boxParams" >
                        <div>
                            <input onChange={(e) => this.onChangeUploadInput(e)} type="file" />
                        </div>
                        <div>
                            <input onChange={(e) => this.onChangeTitleUploadImage(e)} value={this.state.valueTitleImageUpload} placeholder={this.props._t.t('Название')} type="text"/>
                        </div>
                        {/*<div>*/}
                        {/*<input type="text" placeholder="Теги, через запятую"/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="footer">
                        <button id="closePanelUploadFooter">{this.props._t.t('Закрыть')}</button>
                        <button onClick={(e) => funcSibmit(e)} className={classNameUploadButtom}>{this.props._t.t('Загрузить')}</button>
                        {componentSpinerloaderUploadFiles}
                    </div>
                </div>
            </div>
        }
        if (this.state.dataImage.user_items !== undefined) {
            let t = this.props._t
            componentImage = this.state.dataImage.user_items.map(function (prop, id) {
                function selectItem () {
                    funcSelect(prop);
                }
                function selectDelete() {
                    funcDelete(prop);
                }
                return (
                    <div className="itemImageBox" key={id}>
                        <div style={{height: '100%', width: '0px',display: 'inline-block', verticalAlign: 'middle'}}></div>
                        <img src={hostForLocation.getHostName().cms + prop.file_url} alt=""/>
                        <div className="boxInfoImageHover">
                            <button onClick={(e) => selectItem(e)}><i className="fa fa-check" ariaHidden="true"></i>{t.t('Выбрать')}</button>
                        </div>
                        <span onClick={(e) => selectDelete(e)}><i className="fa fa-times" ariaHidden="true"></i></span>
                    </div>
                );
            });
        }
        return (
            <div onClick={(e) => this.onHide(e)} id="backgroundModalBoxImage" className="backgroundModalBoxImage">
                <div className="modalBoxImage">
                    <div className="panelHeaders">
                        <h2>{this.props._t.t('Медиатека')}</h2>
                        <span id="closeModalImage" className="closePanel">X</span>
                    </div>
                    <div className="boxParams" >
                        <div className="boxComponentImage">
                            {componentImage}
                            <div style={{clear: 'both', float: 'none', width: '100%', height: '0px', padding: '0px', margin: '0px', display: 'block'}}></div>
                        </div>
                    </div>
                    <div className="footer">
                        <button onClick={(e) => this.showPanelUpload(e)}>{this.props._t.t('Загрузить')} <i className="fa fa-cloud-upload fa-2x" ariaHidden="true"></i></button>
                        {componentSpinerloaderLoadFiles}
                    </div>
                    {componentUploadPanel}
                </div>
            </div>
        );
    }
}

export default ModalImage;


